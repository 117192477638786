import React, { useState } from 'react';
import {
  NumberInput,
  SelectInput,
  useTranslate,
  useGetIdentity,
} from 'react-admin';
import ImageOrientationInput from '../ImageOrientationInput';
import { makeStyles } from '@material-ui/core/styles';
import { BooleanInput } from 'react-admin';
import { useWidgets } from '../../hooks/useWidgets';

const useStyles = makeStyles({
  main: {},
  select: {},
});

const ConfigTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const { paginationPositionEnum } = useWidgets();
  let classes = useStyles();

  let viewEnum = [
    { id: 'list', name: translate('list') },
    { id: 'brick', name: translate('brick') },
    { id: 'slide', name: translate('slide') },
    { id: 'table', name: translate('table') },
  ];

  let sortPropertyEnum = [
    { id: 'sort', name: translate('sort') },
    { id: 'created_at', name: translate('created_at') },
    { id: 'publish_at', name: translate('publish_at') },
    { id: 'updated_at', name: translate('updated_at') },
    { id: 'title', name: translate('title') },
  ];

  const [isList, setIsList] = useState<boolean>(
    !props.record?.configuration?.view ||
      props.record?.configuration?.view === 'list'
  );

  return (
    <>
      {props.isFolder && (
        <>
          <div>
            <SelectInput
              source="configuration.view"
              label={translate('configuration.view')}
              choices={viewEnum}
              allowEmpty={false}
              className={classes.select}
              defaultValue={'published'}
              style={{ width: 250 }}
              initialValue="list"
              helperText={false}
              onChange={(e: any) => {
                setIsList(e.target.value === 'list');
              }}
            />
            {!isList && (
              <NumberInput
                source="configuration.columns"
                label={translate('configuration.columns')}
                style={{ width: 250 }}
                helperText={false}
              />
            )}
            <ImageOrientationInput />
          </div>

          <div>
            <SelectInput
              source="configuration.sort.property"
              label={translate('configuration.sort.property')}
              choices={sortPropertyEnum}
              allowEmpty={false}
              className={classes.select}
              defaultValue={'publish_at'}
              style={{ width: 250 }}
              helperText={false}
            />

            <SelectInput
              source="configuration.sort.direction"
              label={translate('configuration.sort.direction')}
              choices={[
                {
                  id: 'desc',
                  name: translate('configuration.sort.desc'),
                },
                { id: 'asc', name: translate('configuration.sort.asc') },
              ]}
              allowEmpty={false}
              className={classes.select}
              defaultValue={'publish_at'}
              style={{ width: 250 }}
              helperText={false}
            />
            {identity?.user?.isAdmin && (
              <SelectInput
                label={translate('configuration.pagination.position.title')}
                source={'configuration.pagination.position'}
                choices={paginationPositionEnum}
                allowEmpty={true}
                style={{ width: 250 }}
                helperText={false}
              />
            )}
          </div>

          <BooleanInput
            source="configuration.hideTitle"
            label={translate('configuration.hidePageTitle')}
            defaultValue={false}
            helperText={false}
          />
          <BooleanInput
            source="configuration.hideDescription"
            label={translate('hideDescription')}
            helperText={false}
          />
          <BooleanInput
            source="configuration.hideCategory"
            label={translate('hideCategory')}
            helperText={false}
          />
          <BooleanInput
            source="configuration.hideLocation"
            label={translate('hideLocation')}
            helperText={false}
          />
          {identity?.user?.isAdmin && (
            <BooleanInput
              source="configuration.fullscreen"
              label={translate('configuration.fullscreen')}
              helperText={false}
            />
          )}
        </>
      )}

      <BooleanInput
        source="configuration.hideImage"
        label={translate('configuration.hideImage')}
        helperText={false}
      />

      <BooleanInput
        source="hideMenu"
        label={translate('hideMenu')}
        helperText={false}
      />

      <BooleanInput
        source="configuration.disableFavorite"
        label={translate('configuration.disableFavorite')}
        helperText={false}
        fullWidth
      />

      {!props.disableSharing && (
        <BooleanInput
          source="configuration.disableSharing"
          label={translate('configuration.disableSharing')}
          helperText={false}
          fullWidth
        />
      )}

      <BooleanInput
        source="configuration.disableReload"
        label={translate('configuration.disableReload')}
        helperText={false}
        fullWidth
      />

      {(identity?.user?.isAdmin ||
        identity?.user?.domain?.configuration?.homepageDefaultWidgets ||
        identity?.user?.domain?.configuration?.homepage?.widgets?.length) && (
        <BooleanInput
          source="is_pinned"
          label={translate('is_pinned')}
          helperText={false}
        />
      )}

      <div>
        <NumberInput
          source="sort"
          label={translate('sort')}
          style={{ width: 350 }}
          helperText="Utiliser ce champ pour organiser cette page ou ce dossier dans son dossier parent"
        />
      </div>
    </>
  );
};

export default ConfigTab;
