import React from 'react';
import {
  useTranslate,
  Edit,
  TabbedForm,
  FormTab,
  useGetIdentity,
} from 'react-admin';
import EditToolbar from '../EditToolBar';
import { IconTab } from '../ImageTab';
import MainTab from './MainTab';
import RootTab from './RootTab';
import RssTab from './RssTab';

const CategoryEdit = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <Edit title={translate('categories')} {...props}>
      <TabbedForm
        redirect="list"
        toolbar={<EditToolbar hideDeletion={!identity?.user?.isAdmin} />}
      >
        <FormTab label={translate('infos')}>
          <MainTab mode="edit" />
        </FormTab>
        <FormTab label={translate('image')}>
          <IconTab />
        </FormTab>
        {identity?.user?.isAdmin && (
          <FormTab label={translate('rss')}>
            <RssTab />
          </FormTab>
        )}
        {identity?.user?.isAdmin && (
          <FormTab label={translate('root')}>
            <RootTab />
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};

export default CategoryEdit;
