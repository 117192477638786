import React from 'react';
import { useGetIdentity } from 'react-admin';
import { useTranslate, TextInput } from 'react-admin';

const SocialTab = (props: any) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  return (
    <>
      <TextInput
        source="facebook"
        label={translate('facebook')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="twitter"
        label={translate('twitter')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="instagram"
        label={translate('instagram')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="linkedin"
        label={translate('linkedin')}
        helperText={false}
        fullWidth
      />
      <TextInput
        source="youtube"
        label={translate('youtube')}
        helperText={false}
        fullWidth
      />
      {identity?.user?.isAdmin && (
        <TextInput
          source="tripadvisor"
          label={translate('tripadvisor')}
          helperText={false}
          fullWidth
        />
      )}
    </>
  );
};

export default SocialTab;
