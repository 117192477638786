import React from 'react';
import { useTranslate, Create, TabbedForm, FormTab } from 'react-admin';
import { IconTab } from '../ImageTab';
import MainTab from './MainTab';
import RssTab from './RssTab';

const CategoryCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <Create title={translate('categories')} {...props}>
      <TabbedForm redirect="list">
        <FormTab label={translate('infos')}>
          <MainTab mode="create" />
        </FormTab>
        <FormTab label={translate('image')}>
          <IconTab />
        </FormTab>
        <FormTab label={translate('rss')}>
          <RssTab />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CategoryCreate;
