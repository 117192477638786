export const domaineTypeEnum = [
  { id: 'town', name: 'Mairie' },
  { id: 'association', name: 'Association' },
  { id: 'company', name: 'Entreprise' },
  { id: 'restaurant', name: 'Commerce' },
  { id: 'school', name: 'Ecole' },
  { id: 'run', name: 'Run' },
  { id: 'golf', name: 'Golf' },
];

export const domainePackEnum = [
  { id: 'vip', name: 'VIP' },
  { id: 'premium', name: 'Premium' },
  { id: 'starter', name: 'Starter' },
  { id: 'trial', name: 'Trial' },
];
